import L from 'leaflet'
import React from 'react'
import { Circle, Tooltip, Marker } from 'react-leaflet'

import s from './UserLocationMarker.scss'


type UserLocationMarkerProps = {
  userLocation: [number, number]
  isDone: boolean
  withTooltip?: boolean
}

const markerIcon = new L.DivIcon({
  className: s.markerIcon,
  iconSize: [ 12, 12 ],
  iconAnchor: [ 6, 6 ],
  tooltipAnchor: [ 0, -6 ],
})

const UserLocationMarker: React.FC<UserLocationMarkerProps> = (props) => {
  const { userLocation, isDone, withTooltip } = props
  return (
    <>
      {
        (isDone && userLocation) && (
          <>
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={32 * 2 * 2} />
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={48 * 2 * 2} />
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={64 * 2 * 2} />
            <Marker
              position={userLocation}
              icon={markerIcon}
            >
              {
                withTooltip && (
                  <Tooltip
                    className={s.tooltip}
                    permanent
                    direction="top"
                    opacity={1}
                  >Моё местоположение</Tooltip>
                )
              }
            </Marker>
          </>
        )
      }
    </>
  )
}

export default UserLocationMarker